// Change app name based on your demand
export const APP_NAME = "Finance MIS";
export const APP_DESCRIPTION =
  "View ARR, QRR and MRR builds. View flash report";
export const APP_NAME_ROUTE = "/finance-mis";

export const APP_CONTEXT = APP_NAME_ROUTE;

export const QUERY_PARAMS_REGEX = /^[a-z0-9]*$/i;

export const DATE_FORMAT = "sv-SE";

export const AUTH_CONFIG = {
  signInRedirectURL: `${window.config.AUTH_SIGN_IN_REDIRECT_URL}`,
  signOutRedirectURL: `${window.config.AUTH_SIGN_OUT_REDIRECT_URL}`,
  clientID: `${window.config.ASGARDEO_CLIENT_ID}`,
  baseUrl: `${window.config.ASGARDEO_SERVER_ORIGIN}`,
  scope: ["openid", "email", "groups"],
};

// User configs - Customize according to the choice of user
export const ACTION_CUSTOM_CONFIGS = {
  ROLE_BASED_AUTH: true,
};

// User configs - Customize according to the choice of user
export const USER_CUSTOM_CONFIGS = {
  LAUNCHPAD: false,
};

// Sample previlages
export const PRIVILEGES = {
  ARR_DASHBOARD: 1,
  FLASH_DASHBOARD: 2,
};

export const APP_CONFIG = {
  PAGES: {
    BASE_URL: "/",
    APP: APP_CONTEXT,
    ARR_DASHBOARD: APP_CONTEXT + "/arr-dashboard",
    ARR_BUILD: APP_CONTEXT + "/arr-dashboard/arr-build",
    QRR_BUILD: APP_CONTEXT + "/arr-dashboard/qrr-build",
    MRR_BUILD: APP_CONTEXT + "/arr-dashboard/mrr-build",
    FLASH_DASHBOARD: APP_CONTEXT + "/flash-dashboard",
    HOME: APP_CONTEXT + "/home",
    MANAGE: APP_CONTEXT + "/manage",
    PROFILE: APP_CONTEXT + "/profile",
    PREFERENCES: APP_CONTEXT + "/preferences",
    NOT_FOUND: APP_CONTEXT + "/not-found",
  },
  EMAILS: {
    GET_HELP_EMAIL_TO: "internal-apps-group@wso2.com",
    GET_HELP_EMAIL_SUBJECT: `[HELP] ${APP_NAME}`,
  },
  QUERY_VALUES: {
    VIEW: "view",
    EDIT: "edit",
    ACCOUNT: "account",
    TAGS: "tags",
    ROLES: "roles",
  },
  DASHBOARDS: {
    ARR: "arr-dashboard",
  },
};

export const BU_LIST = {
  INTEGRATION: "Integration-Software",
  INTEGRATION_CLOUD: "Integration-Cloud",
  IAM: "IAM",
  CORPORATE: "Corporate",
  WSO2: "All",
};

export const ERROR_MESSAGE_LIST = {
  SOMETHING_WENT_WRONG: "Something went wrong! Couldn't fetch data.",
  ERROR_OCCURRED: "Error occurred!",
  APP_INITIALIZING_ERROR:
    "An error occurred in initializing the app! Try reloading the page. Please contact the Internal Apps Team if this issue continues.",
  COMMENT_ADD_ERROR: "Error occurred when adding the comment!",
  COMMENT_UPDATE_ERROR: "Error occurred when updating the comment!",
  COMMENT_DELETE_ERROR: "Error occurred when deleting the comment!",
  ACCOUNT_UPDATE_ERROR:
    "Error occurred when updating the account! This may be due to the Cutoff date (15th) is already passed. Hence editing the given Income/Expense GL Account value is not permitted",
};

export const SUCCESS_MESSAGE_LIST = {
  COMMENT_ADD_SUCCESS: "Comment added successfully!",
  COMMENT_UPDATE_SUCCESS: "Comment updated successfully!",
  COMMENT_DELETE_SUCCESS: "Comment deleted successfully!",
  ACCOUNT_UPDATE_SUCCESS: "Account updated successfully!",
};

export const MONTH_HEADERS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const LAUNCHPAD_ITEMS = [];
export const CHANNEL_VS_DIRECT_NOTIFICATION = "The channel and direct split numbers are being reviewed due to data inconsistency.";
